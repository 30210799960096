import "./style.css";
import * as dat from "dat.gui";
import * as THREE from "three";
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper.js";

/**
 * Base
 */
// Debug
const debugObject = {};
const gui = new dat.GUI({
  width: 400,
});

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader();

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  10000
);
camera.position.x = 0;
camera.position.y = 2;
camera.position.z = 35;
scene.add(camera);

/**
 * Light
 */

const ambientLight = new THREE.AmbientLight("#333333", 0.1);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight("white", 0.6);
directionalLight.position.set(5, 10, 5);
scene.add(directionalLight);

/**
 * Default Material
 */

const defaultMaterial = new THREE.MeshPhongMaterial({
  color: "white",
  wireframe: false,
});
const lineMaterial = new THREE.LineBasicMaterial({
  color: "red",
});

/**
 * Bounce Objects
 */

const sphereGeometry = new THREE.SphereGeometry(1, 50, 50);

const boxGeometry = new THREE.BoxGeometry(1, 1, 1, 10, 10, 10);

const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 2, 30, 30);

for (let i = 0; i < 100; i++) {
  const sphere = new THREE.Mesh(sphereGeometry, defaultMaterial);
  sphere.position.set(
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5
  );
  scene.add(sphere);
  const box = new THREE.Mesh(boxGeometry, defaultMaterial);
  box.position.set(
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5
  );
  scene.add(box);
  const cylinder = new THREE.Mesh(cylinderGeometry, defaultMaterial);
  cylinder.position.set(
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5,
    Math.random() * 25 - 25 * 0.5
  );
  scene.add(cylinder);
}

/**
 * Ball
 */

const ballGeometry = new THREE.SphereGeometry(0.1, 50, 50);
const ballMaterial = new THREE.MeshPhongMaterial({ color: "orangered" });
const ball = new THREE.Mesh(ballGeometry, ballMaterial);
ball.position.set(0, 0, 30);
scene.add(ball);

const ballTimeLine = gsap.timeline();
ballTimeLine.pause();

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster();
const objectsToTest = scene.children.filter(
  (child) => child instanceof THREE.Mesh
);
let currentBounce = 0;
let hitPoints = [];
let isRayFinished = false;

const castRay = (rayOrigin, rayDirection, objectsToTest) => {
  // scene.add(new THREE.ArrowHelper(rayDirection, rayOrigin, 1, "white"));

  raycaster.set(rayOrigin, rayDirection);

  const intersects = raycaster.intersectObjects(objectsToTest);

  return intersects;
};

const handleIntersect = (intersects, rayOrigin, rayDirection) => {
  const hitPoint = intersects[0].point;
  // Add hitPoint to hitPoints array
  hitPoints = [...hitPoints, hitPoint];

  ballTimeLine.to(ball.position, {
    duration: 1,
    ease: "bounce.out",
    x: hitPoint.x,
    y: hitPoint.y,
    z: hitPoint.z,
  });

  ballTimeLine.to(camera.position, {
    duration: 1,
    x: hitPoint.x + 0.5,
    y: hitPoint.y + 0.5,
    z: hitPoint.z + 0.5,
  });

  const hitNormal = intersects[0].face.normal;
  // scene.add(new THREE.ArrowHelper(hitNormal, hitPoint, 1, "cyan"));

  // draw line
  const points = [rayOrigin, hitPoint];
  const rayLineGeometry = new THREE.BufferGeometry().setFromPoints(points);
  const rayLine = new THREE.Line(rayLineGeometry, lineMaterial);
  scene.add(rayLine);

  const directness = hitNormal.clone().dot(rayDirection);
  const directnessVector = hitNormal
    .clone()
    .multiply(
      new THREE.Vector3(directness * 2, directness * 2, directness * 2)
    );

  const rayReflectDirection = rayDirection.sub(directnessVector).normalize();
  // scene.add(new THREE.ArrowHelper(rayReflectDirection, hitPoint, 1, "white"));

  return [hitPoint, rayReflectDirection];
};

const handleLaser = (startPoint, startDirection, maxBounceCount) => {
  const intersects = castRay(startPoint, startDirection, objectsToTest);

  if (intersects[0]) {
    const [hitPoint, rayReflectDirection] = handleIntersect(
      intersects,
      startPoint,
      startDirection
    );
    if (currentBounce < maxBounceCount) {
      handleLaser(hitPoint, rayReflectDirection, maxBounceCount);
    } else {
      isRayFinished = true;
    }
  } else {
    isRayFinished = true;
  }

  if (isRayFinished) {
    ballTimeLine.play();
  }
};

window.requestAnimationFrame(() => {
  const rayOrigin = new THREE.Vector3(0, 0, 30);
  const rayDirection = new THREE.Vector3(0, 0, -1);
  rayDirection.normalize();

  handleLaser(rayOrigin, rayDirection, 30);
});

/**
 * Controls
 */
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

window.addEventListener("keydown", (e) => {
  if (e.key === " ") {
    controls.enabled = false;
  }
});

window.addEventListener("keyup", (e) => {
  if (e.key === " ") {
    controls.enabled = true;
  }
});

const objectControls = new TransformControls(camera, canvas);
scene.add(objectControls);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;

debugObject.clearColor = "#282828";
renderer.setClearColor(debugObject.clearColor);
gui.addColor(debugObject, "clearColor").onChange(() => {
  renderer.setClearColor(debugObject.clearColor);
});

/**
 * Helper
 */
// const axesHelper = new THREE.AxesHelper(2);
// axesHelper.position.set(0.01, 0.01, 0.01);
// scene.add(axesHelper)

// const gridHelperX = new THREE.GridHelper(10, 10);
// scene.add(gridHelperX);

// const gridHelperY = new THREE.GridHelper(10, 10);
// gridHelperY.rotation.x = Math.PI * 0.5;
// scene.add(gridHelperY);

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  camera.lookAt(ball.position);

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
